<template>
  <section class="auth-section">
    <div class="container mob-container text-center">
      <!--            <LogoSvg class="logo"/>-->
      <img src="/images/logo_h.png" class="logo" alt="logo"/>
      <h1>{{ $t('set_password') }}</h1>
      <InputGroup type="password" :label="$t('password')" class="default" v-model="password"/>
      <InputGroup type="password" :label="$t('password_confirm')" class="default" v-model="password_confirm" @enter="login"/>
      <div class="text-danger mb-3 small" v-if="password_confirm && password != password_confirm">{{ $t('passwords_do_not_match') }}</div>
      <div class="form-group">
        <button @click="login" :disabled="!valid" class="btn-themed w-100">{{ $t('signin') }}</button>
      </div>
      <div class="error" v-if="error">{{ error }}</div>
    </div>
  </section>
</template>

<script>
import InputGroup from "@/views/parts/general/form/InputGroup"
// import LogoSvg from "@/assets/svg/logo.svg"
import AuthService from "@/services/auth.helper"
import { UsersService } from '@/services/api.service'
import '@/assets/sass/auth.scss'


export default {
  components: {
    // LogoSvg,
    InputGroup
  },
  data() {
    return {
      password: null,
      password_confirm: null,
      error: "",
      id: this.$route.params.id,
      hash: this.$route.params.hash,
    }
  },
  mounted() {

  },
  methods: {
    login() {
      UsersService.initialPassword(this.id, this.hash, this.password).then(res => {
        let r = this.apiResponse(res)
        // console.log(r)
        if(r.success) {
          let user = r.data
          AuthService.login({
            expires_in: 43200*60,
            access_token: user.api_token,
            refresh_token: user.api_token,
          }, user)
          this.$store.commit('saveUser', { user: user, vm: this } )
          this.$router.push('/')
        } else {
          this.error = r.data
        }
      }).catch(err => {
        console.log(err)
        this.error = this.$t("not_found")
      })
    }
  },
  computed: {
    valid() {
      return this.password && this.password === this.password_confirm
    }
  },
  watch: {
    password() {
      this.error = ''
    }
  }
}
</script>

<style lang="scss" scoped>


</style>


